import { Link, Text, TextProps, forwardRef } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

type Props = {
  disableLinks?: boolean;
  includeParagraphs?: boolean;
  children?: string;
} & Omit<TextProps, 'children'>;

export const MarkdownRenderer = forwardRef<Props, 'span'>(
  ({ disableLinks, includeParagraphs, children, ...props }, ref) => (
    <Text ref={ref} as="span" {...props}>
      <ReactMarkdown
        remarkPlugins={[remarkBreaks, remarkGfm]}
        allowedElements={['p', 'a', 'strong', 'em', 'br', 'h1', 'h2', 'h3']}
        components={{
          h1: ({ ...props }) =>
            includeParagraphs ? <Text fontSize="lg">{props.children}</Text> : <>{props.children}</>,
          h2: ({ ...props }) =>
            includeParagraphs ? <Text fontSize="lg">{props.children}</Text> : <>{props.children}</>,
          h3: ({ ...props }) =>
            includeParagraphs ? <Text fontSize="lg">{props.children}</Text> : <>{props.children}</>,
          p: ({ ...props }) =>
            includeParagraphs ? <Text>{props.children}</Text> : <>{props.children}</>,
          a: disableLinks
            ? ({ ...props }) => <>{props.children}</>
            : ({ ...props }) => (
                <Link
                  href={props.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  textDecor="underline"
                  _hover={{ textDecor: 'underline', opacity: 0.6 }}
                >
                  {props.children}
                </Link>
              ),
        }}
      >
        {children?.replace(/\\n/g, '&nbsp; \n')}
      </ReactMarkdown>
    </Text>
  ),
);
