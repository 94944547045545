import { MintingCard } from '@/components/shared/MintingCard';
import { CollectionType } from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import optimizeImage from '@/utils/optimizeImage';
import { routes } from '@/utils/routes';
import { useBreakpointValue, type ContainerProps } from '@chakra-ui/react';
import { CardCarousel } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

type Props = Omit<ContainerProps, 'gap'> & {
  collections: CollectionType[];
};

export const MintingCollections = ({ collections = [], ...containerProps }: Props) => {
  const marketplaceChain = useMarketplaceChain();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation('home');

  return (
    <CardCarousel
      title={t('minting-collections.title')}
      visibleItems={{ base: 1.15, md: 2, lg: 3, '2xl': 4 }}
      scrollAmount={{ base: 1, md: 2, lg: 3, '2xl': 4 }}
      onIndexChange={index => setActiveIndex(index)}
      {...containerProps}
    >
      {collections.map((collection, index) => (
        <MintingCard
          key={`minting-collection-${index}`}
          fallbackImage={optimizeImage(
            collection.banner || collection.sampleImages?.[0] || collection.image || '',
            800,
          )}
          link={{
            href: routes.collections.detail(collection.id ?? '', marketplaceChain.routePrefix),
          }}
          collectionId={collection.id}
          title={collection.name}
          isActive={isMobile && index === activeIndex}
          price={collection.mintStages?.[0]?.price}
          sampleImages={collection.sampleImages}
        />
      ))}
    </CardCarousel>
  );
};
