import { Box, Flex, Heading, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { ChevronLeftThickIcon, ChevronRightThickIcon } from '@sphere/icons';
import { rem } from 'polished';
import { useUITranslation } from '../../context/TranslationProvider';

interface Props {
  activeIndex: number;
  onNavigate: (index: number) => void;
  title?: string;
  scrollAmount?: Partial<Record<string, number>>;
  displayNavigation?: boolean;
  isAtEnd?: boolean;
}

export const CarouselHeader = ({
  activeIndex,
  onNavigate,
  title,
  scrollAmount = { base: 1, md: 3, lg: 4 },
  displayNavigation,
  isAtEnd,
}: Props) => {
  const { t } = useUITranslation();
  const slidesPerGroup = useBreakpointValue(scrollAmount, 'base') || 1;

  return (
    <Box
      display={{ md: 'flex' }}
      alignItems="center"
      justifyContent={title ? 'space-between' : 'flex-end'}
      mb={{ base: 'space.36', md: 'space.28' }}
    >
      {title && (
        <Heading as="h3" size="lg" mb="space.12">
          {title}
        </Heading>
      )}
      {displayNavigation && (
        <Flex display={{ base: 'none', md: 'flex' }} alignItems="center">
          <IconButton
            onClick={() => onNavigate(activeIndex - slidesPerGroup)}
            variant="icon-secondary"
            aria-label={t('previous')}
            icon={<ChevronLeftThickIcon boxSize={rem(16)} viewBox="1 0 20 20" />}
            isDisabled={!activeIndex}
          />
          <IconButton
            ml="space.8"
            onClick={() => onNavigate(activeIndex + slidesPerGroup)}
            variant="icon-secondary"
            aria-label={t('next')}
            icon={<ChevronRightThickIcon boxSize={rem(16)} />}
            isDisabled={isAtEnd}
          />
        </Flex>
      )}
    </Box>
  );
};
