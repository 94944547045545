import { Button, ButtonProps, Flex, forwardRef } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useSphereTheme } from '../../context';

export type ButtonLinkProps = ButtonProps & LinkConnectorProps & { target?: string };

export const ButtonLink = forwardRef(({ children, rightIcon, ...props }: ButtonLinkProps, ref) => {
  const { linkWrapper } = useSphereTheme();

  const buttonProps = useMemo(
    () => ({
      ref,
      ...props,
    }),
    [ref, props],
  );

  return linkWrapper(
    <Button
      as="a"
      display="inline-flex"
      // Mimics Chakra's Tab by pulling the active line up 2px
      mb="-2px"
      {...(rightIcon ? { p: 'space.8', pl: 'space.24', lineHeight: 1 } : {})}
      {...((buttonProps.disabled || buttonProps.isDisabled) && { pointerEvents: 'none' })}
      {...buttonProps}
    >
      {children}
      {rightIcon && (
        <Flex
          bg="primary"
          color="blackBg"
          rounded="full"
          boxSize="2.25em"
          align="center"
          justify="center"
          ml="space.24"
        >
          {rightIcon}
        </Flex>
      )}
    </Button>,
  );
});
