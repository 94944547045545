import { ProjectCard } from '@/components/shared/ProjectCard';
import { CollectionType } from '@/hooks/use-collections';
import { Container, Heading, type ContainerProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

type Props = ContainerProps & {
  collection: CollectionType;
  background?: string;
};

export const ProjectSpotlight = ({ collection, background, ...containerProps }: Props) => {
  const { t } = useTranslation('home');

  return (
    <Container {...containerProps}>
      <Heading size="lg" mb="space.24">
        {t('project-spotlight')}
      </Heading>
      {collection && <ProjectCard collection={collection} background={background} />}
    </Container>
  );
};
