import {
  GetCollectionsV7Response,
  getGetCollectionsV7QueryOptions,
  useGetCollectionsV7,
  type GetCollectionsV7Params,
} from '@/lib/reservoir/reservoir.generated';
import { type ReservoirChain } from '@/utils/chains';
import { keepPreviousData as reactQueryKeepPreviousData } from '@tanstack/react-query';
import { useMarketplaceChain } from './use-marketplace-chain';

export type Params = GetCollectionsV7Params;
export type CollectionType = NonNullable<GetCollectionsV7Response['collections']>[number];

const params: Params = {
  includeMintStages: true,
  useNonFlaggedFloorAsk: true,
  excludeSpam: true,
  excludeEmptyCollections: true,
};

const getCollections = (chain: ReservoirChain, override?: Params, isEnabled?: boolean) => {
  return getGetCollectionsV7QueryOptions(
    { ...params, ...override },
    {
      query: {
        queryKey: [`/collections/v7`, chain.id, params, override],
        enabled: isEnabled,
      },
      request: {
        baseURL: chain.reservoirBaseUrl,
        headers: {
          'x-api-key': chain.reservoirApiKey,
        },
      },
    },
  );
};

const useCollections = (override?: Params, isEnabled?: boolean, keepPreviousData?: boolean) => {
  const chain = useMarketplaceChain();

  const request = useGetCollectionsV7(
    { ...params, ...override },
    {
      query: {
        queryKey: [`/collections/v7`, chain.id, params, override],
        enabled: isEnabled,
        ...(keepPreviousData && { placeholderData: reactQueryKeepPreviousData }),
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );

  return request;
};

export { getCollections, useCollections };
