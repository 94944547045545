import { Heading as ChakraHeading, HeadingProps, useTheme } from '@chakra-ui/react';
import { useFluidSize } from '@sphere/ui';
import { PropsWithChildren } from 'react';

const TEXT_STYLE_NAME = 'home-about-heading';

export const Heading = (headingProps: PropsWithChildren<HeadingProps>) => {
  const theme = useTheme();
  const textStyle = theme.textStyles[TEXT_STYLE_NAME];
  const fontSize = useFluidSize(textStyle.fontSize.base, textStyle.fontSize.md, 'sm');

  return (
    <ChakraHeading
      as="h4"
      textStyle={TEXT_STYLE_NAME}
      fontSize={fontSize}
      fontWeight={textStyle.fontWeight} // setting textStyle doesn’t apply fontWeight for some reason
      lineHeight={textStyle.lineHeight}
      {...headingProps}
    />
  );
};
