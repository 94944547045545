import { Box, BoxProps, keyframes } from '@chakra-ui/react';
import { rem } from 'polished';

import { Circle } from './Circle';

const DEFAULT_CIRCLES = [0.0811, 0.0696, 0.0811, 0.0758];
const FULL_SIZE_RADIAL_GRADIENT = 70.6; // this is full-size (100%) for radial gradients in CSS

const ANIMATION_DURATION = 12; // seconds
const ANIMATION = keyframes`
  from {
    transform: translate(-50%,-50%) rotate(20deg);
    transition-timing-function: ease-in-out;
  }
  to {
    transform: translate(-50%,-50%) rotate(-40deg);
    transition-timing-function: ease-in-out;
  }
`;

type Props = BoxProps & {
  size?: BoxProps['width'];
  circles?: number[];
};

export const SphereCircles = ({
  size,
  circles,
  position,
  w,
  h,
  width,
  height,
  ...boxProps
}: Props) => {
  const sizes = (circles || DEFAULT_CIRCLES).reduce<number[]>(
    (sizes, thickness, index) => (index ? [...sizes, sizes[index - 1] - thickness * 2] : [1]),
    [],
  );

  const holeSize = (circles || DEFAULT_CIRCLES).reduce(
    (size, thickness) => size - thickness * 2,
    1,
  );

  return (
    <Box
      aria-hidden
      w={size || w || width || h || height || rem(200)}
      position={position && position !== 'static' ? position : 'relative'}
      sx={{
        aspectRatio: '1 / 1',
        maskImage: `radial-gradient(
              rgba(0,0,0,0) ${(holeSize * FULL_SIZE_RADIAL_GRADIENT).toFixed(4)}%,
              rgba(0,0,0,1) ${(holeSize * FULL_SIZE_RADIAL_GRADIENT).toFixed(4)}%
            )`,
      }}
      {...boxProps}
    >
      {sizes.map((size, index) => (
        <Circle
          key={index}
          size={size}
          rotation={index * -30}
          transform={'translate(-50%,-50%)'}
          animation={`
            ${ANIMATION}
            ${ANIMATION_DURATION}s
            ${
              (index / sizes.length) * ANIMATION_DURATION
            }s ease-in-out infinite alternate backwards`}
        />
      ))}
    </Box>
  );
};
