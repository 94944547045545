import { CollectionType } from '@/hooks/use-collections';
import { Box, Container, Heading, type BoxProps } from '@chakra-ui/react';
import { useFluidSize } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { CollectionSection } from './CollectionSection';
import { MarketplaceSection } from './MarketplaceSection';
import { RaritySection } from './RaritySection';

type Props = BoxProps & {
  collections?: CollectionType[];
};

export const About = ({ collections, ...boxProps }: Props) => {
  const { t } = useTranslation('home');
  const titleFontSize = useFluidSize('3xl', '5xl');
  const paddingTop = useFluidSize(80, 190);
  const paddingBottom = useFluidSize(80, 240);

  return (
    <Box bg="black" {...boxProps}>
      <Container
        pt={paddingTop}
        pb={paddingBottom}
        display={{ md: 'grid' }}
        gridTemplateColumns="repeat(12, 1fr)"
        gap="gutter"
      >
        <Heading
          as="h3"
          gridColumn="1 / 13"
          fontSize={titleFontSize}
          textAlign="center"
          mb={{ base: 'space.80', md: '1.33em' }}
        >
          {t('about.title')}
        </Heading>

        <Box gridColumn="1 / 13">
          {!!collections?.length && (
            <CollectionSection collections={collections} mt={{ base: 'space.24', md: 0 }} />
          )}
        </Box>

        <MarketplaceSection gridColumn="1 / 7" mt={{ base: 'space.24', md: 0 }} />

        <RaritySection gridColumn="7 / 13" mt={{ base: 'space.24', md: 0 }} />

        {/* TODO(support): uncomment this when the support pages are back online */}
        {/* <HelpSection gridColumn="1 / 13" mt="space.80" /> */}
      </Container>
    </Box>
  );
};
