import { env } from '@/env.mjs';
import { routes } from '@/utils/routes';
import { Box, useTheme, type BoxProps } from '@chakra-ui/react';
import { ButtonLink, SphereCircles, useFluidSize } from '@sphere/ui';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { Heading } from '../Heading';
import { Text } from '../Text';

export const MarketplaceSection = (boxProps: BoxProps) => {
  const { t } = useTranslation('home');
  const theme = useTheme();
  const padding = useFluidSize('space.36', 'space.104', 'md', 'xl', theme.space);
  const paddingTop = useFluidSize('space.32', 'space.80', 'md', 'xl', theme.space);

  return (
    <Box
      background="#1B1B1B"
      px={padding}
      pt={{ ...paddingTop, base: rem(174) }}
      pb={{ base: 'space.48', md: '59%' }}
      position="relative"
      overflow="hidden"
      borderRadius={{ base: 'radius.12', md: undefined }}
      {...boxProps}
    >
      <Box textAlign="center">
        <Heading>
          <Trans i18nKey="home:about.marketplace.title" />
        </Heading>
        <Text mb="space.32" whiteSpace="pre-wrap">
          {t('about.marketplace.body', { percentage: env.NEXT_PUBLIC_MARKETPLACE_FEE / 100 })}
        </Text>
        <ButtonLink href={routes.article.fees} mx="auto" variant="secondary">
          {t('about.marketplace.button.label')}
        </ButtonLink>
      </Box>
      <SphereCircles
        size={{ base: rem(276), md: '86%' }}
        position="absolute"
        top={{ base: 0, md: '100%' }}
        left="50%"
        transform={{
          base: 'translate(-50%,-47%) rotate(180deg)',
          md: 'translate(-50%,-46.3%)',
        }}
      />
    </Box>
  );
};
