import { RarityIcon } from '@/components/shared/RarityIcon';
import { Rarity } from '@/types/rarity';
import { Box, useTheme, type BoxProps } from '@chakra-ui/react';
import { useFluidSize } from '@sphere/ui';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { Heading } from '../Heading';
import { Text } from '../Text';

export const RaritySection = (boxProps: BoxProps) => {
  const { t } = useTranslation('home');
  const theme = useTheme();
  const padding = useFluidSize('space.36', 'space.104', 'md', 'xl', theme.space);
  const paddingTop = useFluidSize('space.32', 'space.80', 'md', 'xl', theme.space);

  return (
    <Box
      background="linear-gradient(180deg, #1B1B1B 64.38%, #38429D 100%)"
      px={padding}
      pt={paddingTop}
      pb={{ base: rem(193), md: '59%' }}
      position="relative"
      overflow="hidden"
      borderRadius={{ base: 'radius.12', md: undefined }}
      {...boxProps}
    >
      <Heading textAlign="center">
        <Trans i18nKey="home:about.rarity.title" />
      </Heading>
      <Text textAlign="center">{t('about.rarity.body')}</Text>
      <Box
        position="absolute"
        bottom="0"
        left="50%"
        width={{ base: rem(252), md: '74%' }}
        transform="translate(-50%, 48.5%)"
      >
        <RarityIcon boxSize="100%" rarity={Rarity.Rare} />
      </Box>
    </Box>
  );
};
