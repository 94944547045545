import { MarkdownRenderer } from '@/components/shared/MarkdownRenderer';
import { CollectionType } from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { routes } from '@/utils/routes';
import { GridItem } from '@chakra-ui/react';
import { formatAddress } from '@onbeam/utils';
import { Card, type CardProps } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { rem } from 'polished';
import { Image, getImageSrcSet } from '../Image';

export type ProjectCardProps = CardProps & {
  collection: CollectionType;
  background?: string;
};

export const ProjectCard = ({ collection, background, ...cardProps }: ProjectCardProps) => {
  const { t } = useTranslation('common');
  const marketplaceChain = useMarketplaceChain();

  return (
    <NextLink
      href={routes.collections.detail(collection.id ?? '', marketplaceChain.routePrefix)}
      passHref
      legacyBehavior
    >
      <Card
        as="a"
        display={{ base: 'block', md: 'grid' }}
        gridTemplateColumns="repeat(12, 1fr)"
        columnGap="gutter"
        rowGap={0}
        position="relative"
        transition="all .2s ease"
        _hover={{ borderColor: 'gray.5' }}
        {...cardProps}
      >
        <GridItem as="figure" colSpan={12} borderRadius="inherit">
          <Image
            src={
              background ||
              collection.banner ||
              collection.sampleImages?.[0] ||
              collection.image ||
              ''
            }
            alt={t('project-card.cover-image', { project: collection.name })}
            sx={{ aspectRatio: { base: '296 / 215', md: 'auto' }, width: '100%', height: '100%' }}
            width={1000}
            height={286}
            objectFit="cover"
            borderTopRadius="inherit"
            overflow="hidden"
            maxH={rem(464)}
            sizes={getImageSrcSet({
              base: '90vw',
              lg: rem(1200),
            })}
          />

          <Image
            src={collection.image || collection.sampleImages?.[0]}
            alt={t('project-card.logo', { project: collection.name })}
            position="absolute"
            top="100%"
            objectFit="cover"
            right={{ base: 'space.8', md: 'space.16' }}
            transform="translateY(-50%)"
            boxSizing="border-box"
            bg="black"
            borderWidth={{ base: '1px', md: '2px' }}
            borderColor="gray.2"
            borderStyle="solid"
            borderRadius="full"
            width={92}
            height={92}
            boxSize={{ base: rem(48), md: rem(96) }}
            sizes={getImageSrcSet({ base: rem(48), md: rem(96) })}
          />
        </GridItem>

        <GridItem
          as="span"
          display="block"
          colStart={1}
          colSpan={5}
          fontSize="xs"
          fontWeight="medium"
          lineHeight={1}
          letterSpacing={{ base: '0.01em', md: '0.25em' }}
          textTransform="uppercase"
          px={{ base: 'space.16', md: 'space.32' }}
          mt={{ base: 'space.20', md: 'space.28' }}
          color="gray.5"
        >
          {t('project-card.featured')}
        </GridItem>

        <GridItem
          as="h3"
          colStart={1}
          colSpan={5}
          fontSize={{ base: 'xl', md: '3xl' }}
          lineHeight={1.1}
          px={{ base: 'space.16', md: 'space.32' }}
          pb={{ md: 'space.48' }}
          mt="space.8"
        >
          {formatAddress(collection?.name)}
        </GridItem>

        <GridItem
          colStart={6}
          colSpan={6}
          mt={{ base: 'space.8', md: 'space.16' }}
          p={{ base: 'space.16', md: 0 }}
          pb={{ md: 'space.48' }}
        >
          <MarkdownRenderer
            fontSize={{ base: 'sm', md: 'md' }}
            lineHeight={1.5}
            color="gray.5"
            disableLinks
          >
            {collection?.description ?? ''}
          </MarkdownRenderer>
        </GridItem>
      </Card>
    </NextLink>
  );
};
