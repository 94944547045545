import { forwardRef, Icon, IconProps } from '@chakra-ui/react';
export default forwardRef<IconProps, 'svg'>((props, svgRef) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={14}
    fill="none"
    viewBox="0 0 17 14"
    ref={svgRef}
    {...props}
  >
    <path
      fill="currentColor"
      d="M13.453 6.114L8.789 1.45 10.02.24l6.842 6.842-6.842 6.842-1.232-1.232 4.664-4.642H.56V6.114h12.892z"
    />
  </Icon>
));
