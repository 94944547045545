import { Box, Container, ContainerProps, useBreakpointValue } from '@chakra-ui/react';
import React, { ReactNode, useMemo, useState } from 'react';
import { A11y, Lazy, Navigation, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CarouselHeader } from './CarouselHeader';

type Props = Omit<ContainerProps, 'gap'> & {
  title: string | JSX.Element;
  moreLink?: string;
  children?: ReactNode;
  visibleItems?: Partial<Record<string, number>>;
  scrollAmount?: Partial<Record<string, number>>;
  navigation?: boolean;
  gap?: number;
  loop?: boolean;
  hideNavigation?: boolean;
  onIndexChange?: (index: number) => void;
};

export const CardCarousel = ({
  children,
  title,
  visibleItems = { base: 1.15, md: 3, lg: 4 },
  scrollAmount = { base: 1, md: 3, lg: 4 },
  navigation,
  gap = 24,
  loop,
  hideNavigation = false,
  onIndexChange,
  ...containerProps
}: Props) => {
  const [swiper, setSwiper] = useState<SwiperType>();
  const slidesPerView = useBreakpointValue(visibleItems, 'lg');

  const displayNavigation = useMemo(
    () =>
      !hideNavigation &&
      navigation !== false &&
      React.Children.count(children) > (slidesPerView || 0),
    [navigation, children, slidesPerView],
  );

  return (
    <Container pb="space.12" variant="breakout" {...containerProps}>
      <CarouselHeader
        title={title}
        scrollAmount={scrollAmount}
        displayNavigation={displayNavigation}
        activeIndex={swiper?.activeIndex || 0}
        isAtEnd={swiper?.isEnd}
        onNavigate={index => swiper?.slideTo(index)}
      />
      <Box
        mx={{
          base: '-space.24',
          lg: '-space.32',
          '2xl': '-space.48',
        }}
        px={{
          base: 'space.24',
          lg: 'space.32',
          '2xl': 'space.48',
        }}
        mt="-space.40"
        pt="space.40"
        mb="-space.64"
        pb="space.64"
        overflow="hidden"
      >
        <Swiper
          watchSlidesProgress // To fix https://dept-nl.atlassian.net/browse/MER-3909, see: https://github.com/nolimits4web/swiper/issues/5814
          slidesPerView={slidesPerView}
          spaceBetween={gap}
          loop={loop}
          lazy
          speed={1200}
          preloadImages={false}
          modules={[Navigation, Lazy, A11y]}
          navigation
          style={{ overflow: 'visible' }}
          onInit={setSwiper}
          {...(onIndexChange && {
            onActiveIndexChange: ({ activeIndex }) => onIndexChange(activeIndex),
          })}
        >
          {React.Children.map(children, (child, index) => (
            // To make the cards the same height, see: https://github.com/nolimits4web/swiper/issues/2372#issuecomment-349004052
            <SwiperSlide style={{ height: 'auto' }} key={index}>
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Container>
  );
};
