import { CollectionType } from '@/hooks/use-collections';
import { Box, useTheme, type BoxProps } from '@chakra-ui/react';
import { useFluidSize } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { CollectionAvatar } from './CollectionAvatar';

const RADIUS = 21; // percent
const ITEM_SIZE = 15.5; // percent
const CIRCUMFERENCES = [1, 6, 12];

const POSITIONS = CIRCUMFERENCES.flatMap((circumference, radius) =>
  Array(circumference)
    .fill(0)
    .map((_, index) => {
      const angle = -((Math.PI * 2) / 3) - (index / circumference) * Math.PI * 2;
      return radius
        ? {
            x: radius * RADIUS * Math.cos(angle),
            y: radius * RADIUS * Math.sin(angle),
          }
        : { x: 0, y: 0 };
    }),
);

type Props = BoxProps & {
  collections: CollectionType[];
};

export const CollectionSection = ({ collections, position, ...boxProps }: Props) => {
  const { t } = useTranslation('home');
  const theme = useTheme();
  const padding = useFluidSize('space.36', 'space.64', 'md', 'xl', theme.space);
  const paddingTop = useFluidSize('space.36', 'space.104', 'md', 'xl', theme.space);
  const paddingBottom = useFluidSize('space.40', 'space.104', 'md', 'xl', theme.space);

  return (
    <Box
      background="linear-gradient(105deg, #1B1B1B 40%, #131313 100%)"
      borderRadius={{ base: 'radius.12', md: undefined }}
      position={position && position !== 'static' ? position : 'relative'}
      overflow="hidden"
      {...boxProps}
    >
      <Box
        paddingX={padding}
        paddingTop={{ ...paddingTop, base: '106%' }}
        paddingBottom={paddingBottom}
        width={{ md: '50%' }}
      >
        <Heading>{t('about.collection.title')}</Heading>
        <Text>{t('about.collection.body')}</Text>
      </Box>

      <Box
        pos="absolute"
        top={{ base: 0, md: '50%' }}
        right={{ base: '50%', md: '20%' }}
        width={{ base: '116.5%', md: '52%' }}
        transform={{ base: 'translate(50%,-16%)', md: 'translate(50%,-50%)' }}
        height="auto"
        sx={{ aspectRatio: '1 / 1' }}
        {...boxProps}
      >
        {collections.slice(0, POSITIONS.length).map((collection, index) => (
          <CollectionAvatar
            link={!!collection.id}
            key={`${index}-${collection.name}`}
            size={`${ITEM_SIZE}%`}
            collection={collection}
            position="absolute"
            top={`${(50 + POSITIONS[index].y).toFixed(2)}%`}
            left={`${(50 + POSITIONS[index].x).toFixed(2)}%`}
            transform="translate(-50%,-50%) rotate(-30deg)"
          />
        ))}
      </Box>
    </Box>
  );
};
