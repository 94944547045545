import { CollectionType } from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { routes } from '@/utils/routes';
import { Box, Image, forwardRef, type BoxProps } from '@chakra-ui/react';
import { gradients } from '@sphere/ui';
import NextLink from 'next/link';
import { rem } from 'polished';

type Props = {
  size?: BoxProps['width'];
  collection: CollectionType;
  link?: boolean;
} & BoxProps;

const InnerComponent = forwardRef(
  ({ collection, size, w, h, width, height, position, ...boxProps }: Props, ref) => (
    <Box
      display="block"
      ref={ref}
      w={size || w || width || h || height || { base: rem(48), md: rem(96) }}
      h="auto"
      overflow="hidden"
      rounded="full"
      sx={{ aspectRatio: '1 / 1' }}
      bgGradient={gradients.retroArcade}
      position={position && position !== 'static' ? position : 'relative'}
      {...boxProps}
    >
      <Image
        src={collection.image}
        srcSet={collection.image}
        alt={collection.name}
        title={collection.name}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%,-50%)"
        width="92.6%"
        height="92.6%"
        textAlign="center"
        textColor="gray.4"
        fontSize="sm"
        lineHeight={1.1}
        rounded="full"
        background="black"
        objectFit="cover"
      />
    </Box>
  ),
);

export const CollectionAvatar = forwardRef(({ link, ...props }: Props, ref) => {
  const marketPlaceChain = useMarketplaceChain();

  if (!link) return <InnerComponent ref={ref} {...props} />;

  return (
    <NextLink
      href={routes.collections.detail(props.collection.id ?? '', marketPlaceChain.routePrefix)}
      passHref
      legacyBehavior
    >
      <InnerComponent
        as="a"
        ref={ref}
        _focus={{ outline: 'none' }}
        _focusVisible={{ outline: '3px solid var(--chakra-colors-outlineBlue)' }}
        {...props}
      />
    </NextLink>
  );
});
