import { Box, forwardRef, LinkBox, LinkBoxProps, useTheme } from '@chakra-ui/react';
import { rem } from 'polished';

const PADDING = 'space.12';

type BackgroundActive = undefined | boolean;

export type CardProps = LinkBoxProps & {
  backgroundAlwaysActive?: BackgroundActive;
  isActive?: boolean;
};

const getStaticPadding = (backgroundAlwaysActive: BackgroundActive) => {
  switch (backgroundAlwaysActive) {
    case true:
      return PADDING;
    default:
      return undefined;
  }
};

export const Card = forwardRef<CardProps, 'div'>(
  ({ backgroundAlwaysActive, isActive, position, children, ...boxProps }, ref) => {
    const { breakpoints } = useTheme();

    return (
      <LinkBox
        ref={ref}
        display="flex"
        flexDirection="column"
        pb="space.4"
        cursor="pointer"
        borderRadius="radius.28"
        borderWidth="1px"
        borderColor="border"
        padding={getStaticPadding(backgroundAlwaysActive)}
        {...boxProps}
        position={position && position !== 'static' ? position : 'relative'}
        sx={{
          // Highlight on hover/focus:
          '&:hover, &:focus-visible': {
            '> .card-bg': {
              opacity: 1,
              padding: PADDING,
            },
          },

          // Highlight if we’re in a carousel that’s only showing 1(ish) slides at
          // a time and we’re the one currently shown:
          '.swiper-slide-active > .card-bg': {
            [`@media screen and (max-width: ${breakpoints.md})`]: {
              opacity: 1,
              padding: PADDING,
            },
          },
        }}
      >
        {!backgroundAlwaysActive && (
          <Box
            className="card-bg"
            display="block"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate3d(-50%,-50%,0)"
            width="100%"
            height="100%"
            boxSizing="content-box"
            borderRadius="inherit"
            transitionProperty="opacity, padding"
            transitionDuration="266ms"
            opacity={isActive ? 1 : 0}
            // from-state of transition, don’t need standardized spacing-tokens here
            padding={isActive ? PADDING : rem(8)}
          />
        )}
        {children}
      </LinkBox>
    );
  },
);
