import { Box, BoxProps } from '@chakra-ui/react';

import { gradients } from '../../theme/colors';

type Props = BoxProps & { size?: number; rotation?: number };

export const Circle = ({ size, rotation, ...boxProps }: Props) => (
  <Box
    w="100%"
    h="100%"
    position="absolute"
    top="50%"
    left="50%"
    // Graphics as a ::before so that we can give the gradient an initial
    // turn (rotation-prop), and still use transform:rotate() on the Circle
    // component without messing up the initial turn/rotation
    _before={{
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      rounded: 'full',
      bgGradient: gradients.prominentBorder,
      transform: `scale(${size}) rotate(${rotation}deg)`,
    }}
    {...boxProps}
  />
);
